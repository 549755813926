<template>
  <section class="join-draft">
    <h2 class="join-draft__heading">Join Draft</h2>
    <form @submit.prevent="joinDraft">
      <input type="text" placeholder="enter player name" class="join-draft__input" v-model="playerName" />
      <br />
      <input type="text" placeholder="enter draft ID" class="join-draft__input" v-model="joinId" :disabled="joinQuery" />
      <br />
      <button class="bidding-tool__button">Join</button>
    </form>
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { assignPlayer2Id, updatePlayerName } from "~/utils/BiddingDatabase";
import { getMyPlayerName, saveMyPlayerName } from "~/utils/LocalStorage";
const { mapMutations, mapState } = createNamespacedHelpers("bidding");
export default {
  props: {
    joinQuery: {
      type: [String, Boolean],
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      joinId: this.joinQuery ? this.joinQuery : "",
      playerName: getMyPlayerName() || ""
    };
  },
  computed: {
    ...mapState({
      draftId: state => state.draftId,
      playerId: state => state.playerId
    })
  },
  methods: {
    ...mapMutations({
      setDraftId: "setDraftId",
      setPlayerId: "setPlayerId"
    }),
    joinDraft() {
      saveMyPlayerName(this.playerName);
      const id =
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15);
      this.setPlayerId(id);
      this.setDraftId(this.joinId);
      const ref = this.$fireStore.collection("drafts").doc(this.joinId);
      this.$store.dispatch("bidding/setActiveDraftRef", ref);
      assignPlayer2Id(id);
      updatePlayerName(2, this.playerName);
    }
  }
};
</script>

<style lang="scss">
.join-draft {
  margin-top: 1rem;
  &__heading {
    @include hemi-title;
    font-size: 2rem;
  }
  &__input {
    @include text-field;
  }
}
</style>