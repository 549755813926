<template>
  <section class="draft-results">
    <h2 class="draft-results__heading">Draft Picks</h2>
    <ul class="draft-results__list">
      <li v-for="result in results" :key="result.character.name" class="draft-results__item">
        <div class="draft-results__character">{{result.character}}</div>
        <div class="draft-results__bids">
          <span class="draft-results__bid" :class="{'draft-results__bid--winner' : isWinner(result, 1)}">
            {{result.playerOneBid}}
          </span>
          <span class="draft-results__bid" :class="{'draft-results__bid--winner' : isWinner(result, 2)}">
            {{result.playerTwoBid}}
          </span>
        </div>
      </li>
    </ul>
    <button @click="downloadResults" class="draft-results__download">Download Picks</button>
  </section>
</template>

<script>
import downloadCsv from "download-csv";
import * as moment from "moment";
import { createNamespacedHelpers } from "vuex";

const { mapState } = createNamespacedHelpers("bidding");
export default {
  computed: {
    ...mapState({
      results: state => state.activeDraft.draftPicks
    }),
    resultsDownload() {
      return this.results.map(result => {
        return {
          characterName: result.character.name,
          p1bid: result.playerOneBid,
          p2bid: result.playerTwoBid
        };
      });
    }
  },
  methods: {
    isWinner(result, playerNumber) {
      if (playerNumber === 1) {
        return result.playerOneBid > result.playerTwoBid;
      } else {
        return result.playerOneBid < result.playerTwoBid;
      }
    },
    downloadResults() {
      const data = this.resultsDownload;
      const columns = {
        characterName: "Character Name",
        p1bid: "Player 1 Bid",
        p2bid: "Player 2 Bid"
      };
      const date = moment().format("YYYYMMDD");
      downloadCsv(data, columns, `Aristeia Draft ${date}`);
    }
  }
};
</script>

<style lang="scss">
.draft-results {
  &__heading {
    @include hemi-title;
    font-size: 1.5rem;
  }
  &__pick-heading {
    @include hemi-title;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  &__list {
    margin-bottom: 0.5rem;
  }
  &__bid {
    &--winner {
      font-weight: 800;
    }
  }
  &__download {
    @include button;
  }
  &__id {
    margin: 0.25rem 0;
  }
}
</style>