<template>
  <section class="bid-controller" v-if="this.currentCharacter">
    <h3 class="bid-controller__heading">Submit Bid</h3>
    <div v-if="otherPlayerCanBid">
      <input
        type="number"
        pattern="\d*"
        min="0"
        :max="pointMax"
        v-model="bidAmount"
        class="bid-controller__input"
      />
      <button id="submit" @click="submitBid" class="bid-controller__button">
        {{ bidText }}
      </button>
    </div>
    <div v-else>
      <button id="submit" @click="passBid" class="bid-controller__button">
        Pass
      </button>
      <button id="submit" @click="takeBid" class="bid-controller__button">
        Take
      </button>
    </div>
  </section>
</template>

<script>
import { cloneDeep, random } from "lodash";
import { createNamespacedHelpers } from "vuex";
import {
  setCurrentCharacter,
  setCharacters,
  decrementPicksRemaining,
  setPicksRemaining,
  setPlayerBid,
  toggleBiddingPlayer,
  addPickToDraft,
  reducePlayerPoints,
  addPlayerCharacter,
  resetBids
} from "~/utils/BiddingDatabase.js";
import { getRandomCharacter } from "~/utils/BiddingTools";
const { mapState } = createNamespacedHelpers("bidding");

export default {
  data() {
    return {
      bidAmount: 0
    };
  },
  computed: {
    ...mapState({
      currentBidder: state => state.activeDraft.currentBidder,
      player1: state => state.activeDraft.player1,
      player2: state => state.activeDraft.player2,
      characters: state => state.activeDraft.characters,
      currentCharacter: state => state.activeDraft.currentCharacter,
      countdownThreshold: state => state.activeDraft.countdownThreshold,
      picksRemaining: state => state.activeDraft.picksRemaining
    }),
    currentPlayer() {
      const bidder = this.currentBidder;
      return this[`player${bidder}`];
    },
    pointMax() {
      const { points } = this.currentPlayer;
      return points;
    },
    bidText() {
      return this.bidAmount === 0 ? "Pass" : "Submit Bid";
    }
  },
  methods: {
    async submitBid() {
      await setPlayerBid(this.currentBidder, parseInt(this.bidAmount, 10));
      toggleBiddingPlayer();
      if (this.currentBidder === 2) {
        this.compareBids();
      }
    },
    passBid() {
      this.bidAmount = 0;
      this.submitBid();
    },
    takeBid() {
      this.bidAmount = 1;
      this.submitBid();
    },
    otherPlayerCanBid() {
      const otherPlayerNumber = this.currentPlayer.playerNumber === 1 ? 2 : 1;
      const otherPlayer = this[`player${otherPlayerNumber}`];
      return otherPlayer.points < 0;
    },
    async compareBids() {
      const character = this.currentCharacter;
      const p1Bid = this.player1.currentBid;
      const p2Bid = this.player2.currentBid;
      const clone1 = cloneDeep(this.player1);
      const clone2 = cloneDeep(this.player2);
      if (p1Bid > p2Bid || p2Bid > p1Bid) {
        const winningPlayer = p1Bid > p2Bid ? clone1 : clone2;
        const winningBid = Math.max(p1Bid, p2Bid);
        reducePlayerPoints(winningPlayer.playerNumber, winningBid);
        addPlayerCharacter(winningPlayer.playerNumber, character);
        resetBids();
      } else {
        resetBids();
      }
      this.bidAmount = 0;
      await addPickToDraft(character, p1Bid, p2Bid);
      this.startNextCharacter();
    },
    startNextCharacter() {
      const { characters: characters1 } = this.player1;
      const { characters: characters2 } = this.player2;
      const max = Math.max(characters1.length, characters2.length);
      const min = Math.min(characters1.length, characters2.length);
      if (this.picksRemaining !== null && this.picksRemaining > 0) {
        decrementPicksRemaining();
        if (this.picksRemaining === 1) {
          this.fillEmptyRosters(1);
          this.fillEmptyRosters(2);
          setCharacters(null);
          setCurrentCharacter(null);
          return;
        }
      }
      if (max >= this.countdownThreshold && this.picksRemaining === null) {
        setPicksRemaining(4 - min);
      }
      this.getCharacter();
    },
    fillEmptyRosters(playerNumber) {
      const player = cloneDeep(this[`player${playerNumber}`]);
      const missingCharacters = 4 - player.characters.length;
      const availableCharacters = cloneDeep(
        this.characters.filter(character => character.tier !== "1")
      );
      let newCharacters = [];
      if (missingCharacters > 0) {
        for (let i = 0; i < missingCharacters; i += 1) {
          const charNumber = random(0, availableCharacters.length - 1);
          const newCharacter = availableCharacters.splice(charNumber, 1);
          newCharacters.push(newCharacter[0]);
        }
        addPlayerCharacter(playerNumber, newCharacters);
        setCharacters(availableCharacters);
      }
    },
    getCharacter() {
      const biddingChars = this.characters;
      let chars;
      if (biddingChars.length) {
        chars = getRandomCharacter(this.characters);
      } else if (this.picksRemaining) {
        // Suppress tier 1 picks
        chars = getRandomCharacter(this.$store.state.aristeia.characters, 1);
      } else {
        chars = getRandomCharacter(this.$store.state.aristeia.characters);
      }
      const [character, characters] = chars;
      if (character[0] !== undefined) {
        setCurrentCharacter(character[0]);
        setCharacters(characters);
      } else {
        this.getCharacter();
      }
    }
  }
};
</script>

<style lang="scss">
.bid-controller {
  &__heading {
    // font-variant: small-caps;
    font-size: 1.25rem;
    margin-bottom: 1.25rem;
  }
  &__input {
    @include text-field;
    font-size: 1.5rem;
    width: 150px;
  }
  &__button {
    @include button;
    display: block;
    margin-top: 1rem;
  }
  &__hidden {
    visibility: hidden;
  }
}
</style>
