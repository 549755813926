<template>
  <section class="active-draft">
    <Player :player="player1" class="active-draft__player active-draft__player--player-one" />
    <Player :player="player2" class="active-draft__player active-draft__player--player-two" />
    <section class="active-draft__character">
      <BidCharacter />
    </section>
    <DraftStatus class="active-draft__draft-status" />
    <DraftResults class="active-draft__draft-results" />
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Player from "~/components/Player/Player";
import BidCharacter from "~/components/BidCharacter/BidCharacter";
import DraftStatus from "~/components/DraftStatus/DraftStatus";
import DraftResults from "~/components/DraftResults/DraftResults";

const { mapState } = createNamespacedHelpers("bidding");
export default {
  components: {
    Player,
    BidCharacter,
    DraftStatus,
    DraftResults
  },
  computed: {
    ...mapState({
      player1: state => state.activeDraft.player1,
      player2: state => state.activeDraft.player2
    })
  }
};
</script>

<style lang="scss">
.active-draft {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: min-content 225px 1fr;
  padding: 1rem 0;
  // overflow: hidden;
  @include tablet {
    grid-template-columns: 1fr 4fr 1fr;
  }
  min-height: 100vh;
  width: 100%;
  &__player {
    grid-column: span 2;
    @include tablet {
      grid-row-start: 1;
    }
    &--player-one {
      grid-column-start: 1;
    }
    &--player-two {
      grid-column-start: 3;
    }
  }
  &__no-draft {
    grid-column: span 4;
    grid-row: span 3;
  }
  &__character {
    grid-column: span 2;
    grid-row: span 2;
    max-height: 70vh;
    @include tablet {
      grid-column: 2;
      grid-row-start: 1;
      grid-row: span 2;
      padding: 0 1rem;
    }
  }
  &__draft-status {
    grid-column: span 2;
    @include tablet {
      grid-column: 1;
      grid-row-start: 2;
      grid-row-end: 4;
    }
  }
  &__draft-results {
    grid-column-start: 3;
    grid-column-end: 5;
    @include tablet {
      grid-column: 3;
      grid-row-start: 2;
      grid-row-end: 4;
    }
  }
}
</style>