<template>
  <section class="bid-character">
    <h2 class="bid-character__name">{{character.name}}</h2>
    <img :src="`/images/cards/carta_1_${character.fileName}.png`" class="bid-character__image"/>
  </section>
</template>

<script>
export default {
  computed: {
    character() {
      return this.$store.state.bidding.activeDraft.currentCharacter;
    },
    image() {
      const {
        fileName
      } = this.$store.state.bidding.activeDraft.currentCharacter;
      return `pinup_${fileName}`;
    }
  }
};
</script>

<style lang="scss">
.bid-character {
  &__name {
    @include hemi-title;
    font-size: 2rem;
    text-align: center;
    word-break: break-word;
  }
  &__image {
    display: block;
    height: 100%;
    margin: auto;
    object-fit: contain;
    width: 100%;
  }
}
</style>