import store from "store2";

/**
 * Clear all saved local storage
 */
export function clearSavedItems() {
  store.clear();
}

/**
 * Get all items in local storage
 */
export function getSavedItems() {
  return store() || [];
}

/**
 * Save a player's name
 * @param {Number} playerNumber The player's number
 * @param {String} name The player's name
 */
export function savePlayerName(playerNumber, name) {
  store(`playerName${playerNumber}`, name);
}

/**
 * Get a saved player's name
 * @param {Number} playerNumber The player number
 */
export function getPlayerName(playerNumber) {
  return store(`playerName${playerNumber}`) || "";
}

/**
 * Save default name for a user
 * @param {String} name The name
 */
export function saveMyPlayerName(name) {
  store("myPlayerName", name);
}

/**
 * Get a user's default saved name
 */
export function getMyPlayerName() {
  return store("myPlayerName");
}
