<template>
  <section class="draft-summary">
    <Player :player="player1" class="draft-summary__element draft-summary__player draft-summary__player--player1" />
    <Player :player="player2" class="draft-summary__element draft-summary__player draft-summary__player--player2" />
    <DraftResults class="draft-summary__element draft-summary__draft-results"  />
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Player from "~/components/Player/Player";
import DraftResults from "~/components/DraftResults/DraftResults";
const { mapState } = createNamespacedHelpers("bidding");
export default {
  components: {
    Player,
    DraftResults
  },
  computed: {
    ...mapState({
      player1: state => state?.activeDraft?.player1,
      player2: state => state?.activeDraft?.player2
    })
  }
};
</script>

<style lang="scss">
.draft-summary {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  @include tablet {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>