<template>
  <section class="player">
    <h2 class="player__name">{{player.name}}</h2>
    <BidController :class="{'bid-controller__hidden': !playerIsBidding || !activePlayer }" />
    <section v-if="player.characters.length > 0" class="player__characters">
      <h3 class="player__character-heading">Characters</h3>
      <ul class="player__character-list">
        <li v-for="character in player.characters" :key="character.name" class="player__character-item">
          <img :src="`/images/logos/logo_${character.fileName}.svg`" class="player__pick-logo"/>
        </li>
      </ul>
    </section>
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { isEmpty } from "lodash";

import BidController from "~/components/BidController/BidController";

const { mapState } = createNamespacedHelpers("bidding");

export default {
  props: {
    player: {
      type: Object,
      name: "",
      points: 0,
      characters: [],
      number: 0
    }
  },
  components: {
    BidController
  },
  computed: {
    ...mapState({
      currentCharacter: state => state.activeDraft.currentCharacter,
      currentBidder: state => state.activeDraft.currentBidder,
      player1: state => state.activeDraft.player1,
      player2: state => state.activeDraft.player2,
      playerId: state => state.playerId
    }),
    playerIsBidding() {
      return (
        !isEmpty(this.currentCharacter) &&
        this.currentBidder === this.$props.player.playerNumber
      );
    },
    isMultiplayer() {
      return this.player1.id !== null && this.player2.id !== null;
    },
    activePlayer() {
      if (this.isMultiplayer) {
        return this[`player${this.currentBidder}`].id === this.playerId;
      }
      return true;
    }
  }
};
</script>

<style lang="scss">
.player {
  display: grid;
  grid-template-rows: minmax(24px, min-content) min-content min-content;
  &__name {
    @include hemi-title;
    font-size: 1.5rem;
  }
  &__character-heading {
    @include hemi-title;
    margin-bottom: 0.5rem;
  }
  &__characters {
    grid-row: 3;
  }
  > * + * {
    margin-top: 1rem;
  }
  &__character-list {
    list-style-type: none;
    padding: 0;
  }
  &__character-item {
    & + & {
      margin-top: 0.25rem;
    }
  }
  &__pick-logo {
    height: 45px;
  }
}
</style>