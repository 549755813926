<template>
  <section class="draft-status" v-if="this.currentCharacter">
    <h2 class="draft-status__heading">Draft Status</h2>
    <h3 class="draft-status__subhead" v-if="picksRemaining !== null && picksRemaining > 0">Picks Remaining: {{picksRemaining}}</h3>
    <h3 class="draft-status__subhead">Points Remaining</h3>
    <div class="draft-results__player-points">
      <span class="draft-results__player-name">Player 1:</span> {{pointsRemaining.player1}}
    </div>
    <div class="draft-results__player-points">
      <span class="draft-results__player-name">Player 2:</span> {{pointsRemaining.player2}}
    </div>
    <div v-if="draftId" class="draft-status__id">
      <strong>Draft ID:</strong> <a id="copy-id" class="draft-results__id-link" :href="`?join=${draftId}`" @click.prevent="copyId">{{draftId}}</a>
    </div>
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapState } = createNamespacedHelpers("bidding");

export default {
  computed: {
    ...mapState({
      currentCharacter: state => state.activeDraft.currentCharacter,
      picksRemaining: state => state.activeDraft.picksRemaining,
      player1: state => state.activeDraft.player1,
      player2: state => state.activeDraft.player2,
      draftId: state => state.draftId
    }),
    pointsRemaining() {
      return {
        player1: this.player1.points,
        player2: this.player2.points
      };
    }
  },
  methods: {
    copyId() {
      navigator.permissions.query({ name: "clipboard-write" }).then(result => {
        if (result.state == "granted" || result.state == "prompt") {
          navigator.clipboard
            .writeText(`https://aristeia-tools.op74.net/?join=${this.draftId}`)
            .then(
              function success() {
                alert("Copied join link to clipboard.");
              },
              function failure() {
                alert("Could not copy join link to clipboard.");
              }
            );
        } else {
          alert("Could not copy join link to clipboard.");
        }
      });
    }
  }
};
</script>

<style lang="scss">
.draft-status {
  &__heading {
    @include hemi-title;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  &__subhead {
    font-weight: 600;
    margin-bottom: 0.25rem;
  }
  &__player-name {
    font-weight: 700;
  }
  &__id {
    margin-top: 0.5rem;
  }
}
</style>