/* global $nuxt */

import { cloneDeep } from "lodash";

const playerProperties = [
  "currentBid",
  "name",
  "points",
  "characters",
  "number",
  "hasBid"
];

function clonePlayer(playerNumber) {
  return cloneDeep(bidding().activeDraft[`player${playerNumber}`]);
}

function bidding() {
  return $nuxt.$store.state.bidding;
}

function activeDraft() {
  return $nuxt.$store.state.bidding.activeDraft;
}

/**
 * Update a field in the firestore
 * @param {Object} update
 */
export async function updateFireStoreField(update) {
  try {
    await $nuxt.$fireStore
      .collection("drafts")
      .doc(bidding().draftId)
      .update(update);
  } catch (error) {
    console.error(`FireStore Error: ${error}`);
  }
}

export async function setCurrentCharacter(character) {
  await updateFireStoreField({ currentCharacter: character });
}

export async function setCharacters(characters) {
  await updateFireStoreField({ characters: characters });
}

export async function resetBids() {
  await updatePlayerProperty(1, "currentBid", null);
  await updatePlayerProperty(1, "hasBid", false);
  await updatePlayerProperty(2, "currentBid", null);
  await updatePlayerProperty(2, "hasBid", false);
}

export async function setPicksRemaining(count) {
  await updateFireStoreField({
    picksRemaining: count
  });
}

export async function decrementPicksRemaining() {
  await updateFireStoreField({
    picksRemaining: $nuxt.$store.state.bidding.activeDraft.picksRemaining - 1
  });
}

export async function updatePlayerName(playerNumber, name) {
  await updatePlayerProperty(playerNumber, "name", name);
}

export async function setPlayerBid(playerNumber, bidAmount) {
  await updatePlayerProperty(playerNumber, "currentBid", bidAmount);
}

export async function addPlayerCharacter(playerNumber, character) {
  const clonedPlayer = clonePlayer(playerNumber);
  let newCharacterArray = clonedPlayer.characters;
  if (Array.isArray(character)) {
    newCharacterArray = newCharacterArray.concat(character);
  } else {
    newCharacterArray.push(character);
  }
  await updatePlayerProperty(playerNumber, "characters", newCharacterArray);
}

export async function reducePlayerPoints(playerNumber, points) {
  const clonedPlayer = clonePlayer(playerNumber);
  clonedPlayer.points -= points;
  await updatePlayerProperty(playerNumber, "points", clonedPlayer.points);
}

export async function addPickToDraft(character, playerOneBid, playerTwoBid) {
  const clonedDraft = cloneDeep(activeDraft().draftPicks);
  const pick = {
    pickNumber: clonedDraft + 2,
    character: character.name,
    playerOneBid: playerOneBid,
    playerTwoBid: playerTwoBid
  };
  clonedDraft.push(pick);
  await updateFireStoreField({
    draftPicks: clonedDraft
  });
  $nuxt.$fireStore.collection("allPicks").add({ ...pick });
}

function checkForValidPropertyName(propertyName) {
  if (playerProperties.indexOf(propertyName) < 0) {
    throw new Error("Not a valid player property!");
  }
}

/**
 * Updates a property on a player
 * @param {Number} playerNumber
 * @param {String} propertyName The name of the property
 * @param {*} value The new value
 */
export async function updatePlayerProperty(playerNumber, propertyName, value) {
  try {
    checkForValidPropertyName(propertyName);
    await updateFireStoreField({
      [`player${playerNumber}.${propertyName}`]: value
    });
  } catch (error) {
    console.error(error);
  }
}

export async function assignPlayer2Id(id) {
  await $nuxt.$fireStore
    .collection("drafts")
    .doc(bidding().draftId)
    .update({
      "player2.id": id
    });
}

export async function toggleBiddingPlayer() {
  const newBidder = activeDraft().currentBidder === 1 ? 2 : 1;
  await updateFireStoreField({
    currentBidder: newBidder
  });
}

class DraftPick {
  constructor(character, playerOneBid, playerTwoBid) {
    this.character = character;
    this.playerOneBid = playerOneBid;
    this.playerTwoBid = playerTwoBid;
  }
}
