import { cloneDeep, random, differenceBy, isEqual } from "lodash";

/**
 * A character entry
 * @typedef {Object} CharacterEntry
 * @property {String} name The character's name
 * @property {Number} tier  The character's tier
 */

/**
 * Make a clone of the characters in the store
 * @param {Object} state The Vuex state
 * @returns {CharacterEntry[]}
 */
function cloneCharacters(state) {
  return cloneDeep(state.aristeia.characters);
}

/**
 * Get a random character
 * @param {CharacterEntry[]} characters
 * @param {Number} excludeTier // Don't allow characters of this tier
 * @returns {Array} Returns the character and the remainder of the characters
 */
export function getRandomCharacter(characters, excludeTier = false) {
  let clonedCharacters = cloneDeep(characters);
  if (excludeTier) {
    clonedCharacters = clonedCharacters.filter(
      character => parseInt(character.tier, 10) !== excludeTier
    );
  }
  const charNumber = random(0, characters.length - 1);
  const character = clonedCharacters.splice(charNumber, 1);
  return [character, clonedCharacters];
}

/**
 * Generates a list of available characters, in case we need to restart.
 * @param {CharacterEntry[]} fullList
 * @param {Object} player1
 * @param {Object} player2
 * @returns {CharacterEntry[]}
 */
export function filterFullListAgainstSelections(fullList, player1, player2) {
  const allSelections = player1.characters.concat(player2.characters);
  const difference = differenceBy(fullList, allSelections, isEqual);
  return difference;
}

/**
 * @typedef {Class} PlayerRecord
 * @property {Number} playerNumber
 * @property {String} name
 * @property {Number} currentBid
 * @property {Number} points
 * @property {CharacterEntry[]}
 */
export class PlayerRecord {
  constructor(
    playerNumber,
    playerId,
    name,
    currentBid = 0,
    points = 10,
    characters = []
  ) {
    this.id = playerId;
    this.name = name;
    this.currentBid = currentBid;
    this.points = points;
    this.characters = characters;
    this.playerNumber = playerNumber;
    this.hasBid = false;
  }
}

export class Draft {
  constructor(
    characters,
    currentCharacter,
    currentBidder,
    countdownThreshold,
    picksRemaining,
    player1,
    player2,
    draftPicks = []
  ) {
    this.characters = characters;
    this.currentCharacter = currentCharacter;
    this.currentBidder = currentBidder;
    this.countdownThreshold = countdownThreshold;
    this.picksRemaining = picksRemaining;
    this.player1 = player1;
    this.player2 = player2;
    this.draftPicks = draftPicks;
  }
}
