<template>
  <section class="bidding-tool">
    <DraftSummary class="bidding-tool__draft-summary" v-if="draftComplete" />
    <ActiveDraft class="bidding-tool__active-draft" v-if="draftInProgress" />
    <section v-if="!draftComplete && !draftInProgress" class="bidding-tool__no-draft">
      <StartDraft v-if="!joinQuery" />
      <JoinDraft :join-query="joinQuery" />
    </section>
  </section>
</template>

<script>
import { get } from "lodash";
import { createNamespacedHelpers } from "vuex";
import ActiveDraft from "~/components/ActiveDraft/ActiveDraft";
import StartDraft from "~/components/StartDraft/StartDraft";
import JoinDraft from "~/components/JoinDraft/JoinDraft";
import DraftSummary from "~/components/DraftSummary/DraftSummary";

const { mapActions, mapState } = createNamespacedHelpers("bidding");

export default {
  components: {
    ActiveDraft,
    StartDraft,
    JoinDraft,
    DraftSummary
  },
  data() {
    return {
      drafts: []
    };
  },
  computed: {
    ...mapState({
      currentCharacter: state => state.activeDraft.currentCharacter,
      picksRemaining: state => state.activeDraft.picksRemaining
    }),
    draftInProgress() {
      return this.currentCharacter;
    },
    draftComplete() {
      return this.currentCharacter === null && this.picksRemaining === 0;
    },
    joinQuery() {
      return get(this.$route.query, "join", false);
    }
  },
  methods: {
    ...mapActions({
      setDraftsRef: "setDraftsRef"
    }),
    getPoints(playerNumber) {
      return this[`player${playerNumber}`].points;
    }
  }
};
</script>

<style lang="scss">
.bidding-tool {
  &__button {
    @include button;
    margin-top: 1rem;
  }
}
</style>