<template>
  <section class="start-draft">
    <h2 class="start-draft__heading">Start Draft</h2>
    <h3 class="start-draft__subhead">Player Names</h3>
    <input v-model="p1Name" class="start-draft__input" text="text" placeholder="Player 1 Name"/>
    <input v-model="p2Name" class="start-draft__input" text="text" placeholder="Player 2 Name"/>
    <h3 class="start-draft__subhead">Starting Points</h3>
    <input v-model="startingPoints" class="start-draft__input" type="number" />
    <h3 class="start-draft__subhead">Countdown Threshold</h3>
    <input v-model="countdownThreshold" class="start-draft__input" type="number" />
    <br />
    <h3 class="start-draft__subhead"><input class="start-draft__checkbox" type="checkbox" v-model="allowTierOne" />Allow Tier One?</h3>
    <button @click="startDraft" class="bidding-tool__button">{{buttonText}}</button>
  </section>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { setCurrentCharacter, setCharacters } from "~/utils/BiddingDatabase";
import { getRandomCharacter, PlayerRecord, Draft } from "~/utils/BiddingTools";
import {
  savePlayerName,
  saveMyPlayerName,
  getPlayerName,
  getMyPlayerName
} from "~/utils/LocalStorage";
const { mapMutations, mapState } = createNamespacedHelpers("bidding");

export default {
  data() {
    return {
      startingPoints: 20,
      countdownThreshold: 4,
      p1Name: getMyPlayerName() || getPlayerName(1) || "",
      p2Name: getPlayerName(2) || "",
      allowTierOne: true
    };
  },
  computed: {
    ...mapState({
      player1: state => state.activeDraft.player1,
      player2: state => state.activeDraft.player2,
      draftId: state => state.draftId,
      characters: state => state.activeDraft.characters,
      playerId: state => state.playerId
    }),
    buttonText() {
      const p1PickCount = this.player1.characters.length;
      const p2PickCount = this.player2.characters.length;
      if (p1PickCount + p2PickCount < 8) {
        return "Start Draft";
      }
      return "New Draft";
    }
  },
  methods: {
    ...mapMutations({
      setDraftId: "setDraftId",
      setPlayerId: "setPlayerId"
    }),
    setUpDraft(id) {
      this.setDraftId(id);
      const ref = this.$fireStore.collection("drafts").doc(this.draftId);
      this.$store.dispatch("bidding/setActiveDraftRef", ref);
      const startingChars = this.allowTierOne
        ? this.$store.state.aristeia.characters
        : this.$store.state.aristeia.characters.filter(
            character => character.tier !== "1"
          );
      const chars = getRandomCharacter(startingChars);
      const [character, characters] = chars;
      setCurrentCharacter(character[0]);
      setCharacters(characters);
    },
    startDraft() {
      const id =
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15);
      this.setPlayerId(id);
      this.p1Name = this.p1Name === "" ? "Player 1" : this.p1Name;
      this.p2Name = this.p2Name === "" ? "Player 2" : this.p2Name;
      savePlayerName(1, this.p1Name);
      saveMyPlayerName(this.p1Name);
      savePlayerName(2, this.p2Name);
      const player1 = new PlayerRecord(
        1,
        id,
        this.p1Name,
        null,
        this.startingPoints,
        []
      );
      const player2 = new PlayerRecord(
        2,
        null,
        this.p2Name,
        0,
        this.startingPoints,
        []
      );
      const newDraft = new Draft(
        this.allowTierOne
          ? this.$store.state.aristeia.characters
          : this.$store.state.aristeia.characters.filter(
              character => character.tier !== "1"
            ),
        null,
        1,
        this.countdownThreshold,
        null,
        { ...player1 },
        { ...player2 },
        []
      );
      const { setUpDraft } = this;
      this.$fireStore
        .collection("drafts")
        .add({ ...newDraft })
        .then(function({ id }) {
          setUpDraft(id);
        });
    }
  }
};
</script>

<style lang="scss">
.start-draft {
  &__heading {
    @include hemi-title;
    font-size: 2rem;
  }
  &__subhead {
    font-size: 1.25rem;
    margin: 0.25rem 0;
  }
  &__input {
    @include text-field;
  }
  &__checkbox {
    height: 1.25rem;
    margin-right: 0.5rem;
  }
}
</style>